<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <a
        href="https://www.onepack.tech/"
        target="_blank"
        rel="noopener noreferrer"
        :class="$style.logo"
      >

            <img src="/favicon.png" style="width:55px"/>


        <strong class="mr-2">OnePack</strong>
      </a>
      <br />
      <p class="mb-0">
        Copyright © {{ new Date().getFullYear() }} Tous Droits Réservés.
        <a
          href="https://www.onepack.tech/"
          target="_blank"
          rel="noopener noreferrer"
        >OnePack</a>
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>


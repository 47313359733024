import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    issuesHistory: 'Issues',
    projectManagement: 'Project',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      editProfile: 'Edit Profile',
      logout: 'Logout',
    },
  },
  menu:{
    category:{
      contentCategory:"Manage content",
      general:"General",
      generalInformation:"General Informations",
      ItesCommunity:"ITES community",
    },
    items:{
      publications:"Publications",
      __dashboard: "Home",
      accounts:"Accounts",
      debate: 'Debate',
      news:"News",
      agenda:"Agenda",
      contact: "Contact",
      adhèrents:"Members",
      inscrits:"Registered",
      listerPub:"All publications",
      addPub:"Add publication",
      poll:"Poll",
      lister_actualites:"All News",
      ajouter_actualites:"Add news",
      category_actualites:"All categories",
      debats:"All Debates",
      ajouterdebat:"Add Debates",
      category_pub:"All categories",
      messages:'Messages',
      newsletter:'Newsletter',
      slider:"Slider",
      galerie:"Gallery",
      lister_bilan:"All reviews",
      ajouter_bilan:"Add balance sheet",
      category_bilan:"Balance sheet categories",
      bibliographies:'Libraries',
      listerbib:'Libraries List',
      ajouterBib:'Add library',
      podcast:'Podcast', 
      video:'Videos',
      organigrame:"Organizational chart",
      accessInformation:"Access to information ",    
      category_bib:"All categories", 
    },
  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}

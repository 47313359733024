import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

const router = createRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  hash: false,  
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: '/acceuil',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          path: '/acceuil',
          meta: { title: 'Acceuil', roles:['admin','Acceuil'] },
          component: () => import('./views/acceuil'),
        },
        {
          path: '/comptes',
          meta: { title: 'Comtpes' , roles:['admin','Comptes']},
          component: () => import('./views/comptes'),
        },
        {
          path: '/publications',
          meta: { title: 'Publications',roles:['admin','publications','add_publication'] },
          component: () => import('./views/publications'),
        },
        {
          path: '/ajouterpub',
          meta: { title: 'ajouter publications' ,roles:['admin','add_publication']},
          component: () => import('./views/ajouterpub'),
        },
        {
          path: '/editerpub/:id',
          meta: { title: 'Editer publication',roles:['admin','add_publication'] },
          name:"editerpub",
          component: () => import('./views/EditPub'),
        },  
        {
          path: '/bibliographies',
          meta: { title: 'Bibliography',roles:['admin','bibliographies','add_bibliographies'] },
          component: () => import('./views/bibliographies'),
        },
        {
          path: '/ajouterbib',
          meta: { title: 'ajouter bibliographies' ,roles:['admin','add_bibliographies']},
          component: () => import('./views/ajouterBib'),
        },
        {
          path: '/editerbib/:id',
          meta: { title: 'Editer bibliographies',roles:['admin','add_bibliographies'] },
          name:"editerbib",
          component: () => import('./views/EditerBib'),
        },
        {
          path: '/category_bib',
          meta: { title: 'catégories de bibliothèque', roles:['admin','add_bibliographies']},
          component: () => import('./views/category_bib'),
        },          
        {
          path: '/editernews/:id',
          meta: { title: 'Editer actualité',roles:['admin','actualite'] },
          name:"editernews",
          component: () => import('./views/EditerNews'),
        },                      
        {
          path: '/category_pub',
          meta: { title: 'catégories de publications', roles:['admin','add_publication']},
          component: () => import('./views/category_pub'),
        },  
        {
          path: '/actualites',
          meta: { title: 'Actualités',roles:['admin','actualite'] },
          component: () => import('./views/actualites'),
        },
        {
          path: '/ajouter_actualites',
          meta: { title: 'ajouter Actualité',roles:['admin','actualite'] },
          component: () => import('./views/ajouterActualites'),
        },
        {
          path: '/category_actualites',
          meta: { title: 'catégories de actualité',roles:['admin','actualite'] },
          component: () => import('./views/category_actualites'),
        },  
        {
          path: '/bilans',
          meta: { title: 'Tous les bilans',roles:['admin','bilans'] },
          component: () => import('./views/bilans'),
        },
        {
          path: '/ajouter_bilan',
          meta: { title: 'ajouter bilan',roles:['admin','bilans'] },
          component: () => import('./views/ajouter_bilan'),
        },
        {
          path: '/category_bilan',
          meta: { title: 'Catégories de bilan',roles:['admin','bilans'] },
          component: () => import('./views/category_bilan'),
        },  
        {
          path: '/editerbilan/:id',
          meta: { title: 'Editer bilans',roles:['admin','bilans'] },
          name:"editerbilan",
          component: () => import('./views/EditerBilan'),
        },              
        {
          path: '/sondage',
          meta: { title: 'Sondages',roles:['admin','sondage'] },
          component: () => import('./views/sondage'),
        },       
        {
          path: '/agenda',
          meta: { title: 'Agenda',roles:['admin','agendas'] },
          component: () => import('./views/agenda'),
        },
        {
          path: '/galerie',
          meta: { title: 'Galerie',roles:['admin','galerie'] },
          component: () => import('./views/galerie'),
        },        
        {
          path: '/contact',
          meta: { title: 'Contact ITES', roles:['admin','contact'] },
          component: () => import('./views/contact'),
        },      
        
        {
          path: '/accessInformation',
          meta: { title: 'Accées a l\i information', roles:['admin','contact'] },
          component: () => import('./views/accessInformation'),
        },        
        {
          path: '/messages',
          meta: { title: 'liste des messages', roles:['admin','contact'] },
          component: () => import('./views/messages'),
        },
        {
          path: '/newsletter',
          meta: { title: 'liste des inscrits' ,roles:['admin','inscrit']},
          component: () => import('./views/newsletter'),
        },
        {
          path: '/video',
          meta: { title: 'Contenue media' ,roles:['admin']},
          component: () => import('./views/video'),
        },
        {
          path: '/podcast',
          meta: { title: 'Podcast' ,roles:['admin']},
          component: () => import('./views/podcast'),
        },
        {
          path: '/organigrame',
          meta: { title: 'Organigrame' ,roles:['admin','contact']},
          component: () => import('./views/organigrame'),
        },  
        {
          path: '/slider',
          meta: { title: 'Slider' ,roles:['admin','contact']},
          component: () => import('./views/slider'),
        }, 
        // VB:REPLACE-END:ROUTER-CONFIG      
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          name: 'route404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          name: 'login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 100)
  if (to.matched.some((record) => record.meta.authRequired)) {

    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      //route protected
      if (to.meta.roles.some((val) => store.state.user.access.includes(val))) {
        next()
      }

      else {
        next({
          path: '/auth/500',
          query: { redirect: to.fullPath },
        })
      }

    }
  }
  else {
    next()
  }
})

export default router

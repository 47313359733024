export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    category: true,
    title: 'Générale',
    key: 'general',
    roles:['admin','Acceuil'],
  },
  {
    title: 'Acceuil',
    key: '__dashboard',
    url: '/acceuil',
    icon: 'fe fe-home',
    roles:['admin','Acceuil'],
  },
  {
    title: 'Comtpes',
    key: 'accounts',
    url: '/comptes',
    icon: 'fe fe-users',
    roles:['admin','Comptes'],
  },
  {

    category: true,
    title: 'Gérer contenues',
    key: 'contentCategory',
    roles:['admin','actualite','add_publication','agendas','debats','publications'],
  },
  {
    title: 'Publications',
    key: 'publications',
    url: '/publications',
    icon: 'fe fe-align-center',
    roles:['admin','publications','add_publication'],
    children: [
      {
        title: "Tous les publications",
        key: "listerPub",
        url: "/publications",
        icon: "fe fe-info",
        roles:['admin','publications'],
      },
      {
        title: 'Ajouter publications',
        key: 'addPub',
        url: '/ajouterpub',
        icon: "fe fe-eye",
        roles:['admin','add_publication'],
      },
      {
        title: "Tous les catégories",
        key: "category_pub",
        url: "/category_pub",
        icon: "fe fe-info",
        roles:['admin','add_publication'],
      }
    ],
  },
  { 
    title: 'Actualités',
    key: 'news',
    url: '/actualites',
    icon: 'fe fe-file-plus',
    roles:['admin','actualite'],
    children: [
      {
        title: "Tous les Actualités",
        key: "lister_actualites",
        url: "/actualites",
        icon: "fe fe-info",
        roles:['admin','actualite'],
      },
      {
        title: 'Ajouter Actualités',
        key: 'ajouter_actualites',
        url: '/ajouter_actualites',
        icon: "fe fe-eye",
        roles:['admin','actualite'],
      },
      {
        title: "Tous les catégories",
        key: "category_actualites",
        url: "/category_actualites",
        icon: "fe fe-info",
        roles:['admin','actualite'],
      }
    ],    
  },
  {
    title: 'Sondages',
    key: 'poll',
    url: '/sondage',
    icon: 'fe fe-sidebar',
    roles:['admin','sondage'],    
  },
  {
    title: 'Vidéos de l\'ITES',
    key: 'video',
    url: '/video',
    icon: 'fe fe-calendar',
    roles:['admin'],
  },
  {
    title: 'Podcast',
    key: 'podcast',
    url: '/podcast',
    icon: 'fe fe-calendar',
    roles:['admin'],
  },
  {
    title: 'Agenda',
    key: 'agenda',
    url: '/agenda',
    icon: 'fe fe-calendar',
    roles:['admin','agendas'],
  },
  {
    title: 'Galerie',
    key: 'galerie',
    url: '/galerie',
    icon: 'fe fe-zoom-in',
    roles:['admin','galerie'],
  },  
  {
    category: true,
    title: 'Information génerales',
    key: 'generalInformation',
    roles:['admin','contact'],
  },
  {
    title: 'Organigrame',
    key: 'organigrame',
    url: '/organigrame',
    icon: 'fe fe-zoom-in',
    roles:['admin','contact'],
  },
  {
    title: 'Slider',
    key: 'slider',
    url: '/slider',
    icon: 'fe fe-zoom-in',
    roles:['admin','contact'],
  },
  {
    title: 'Contact ITES',
    key: 'contact',
    url: '/contact',
    icon: 'fe fe-zoom-in',
    roles:['admin','contact'],
  },
  {
    title: 'Accées a l\'information',
    key: 'accessInformation',
    url: '/accessInformation',
    icon: 'fe fe-zoom-in',
    roles:['admin','contact'],
    children: [
      {
        title: "Accées a l\'information",
        key: "accessInformation",
        url: "/accessInformation",
        icon: "fe fe-info",
        roles:['admin','contact'],
      },      
      {
        title: "Tous les bilans",
        key: "lister_bilan",
        url: "/bilans",
        icon: "fe fe-info",
        roles:['admin','contact'],
      },
      {
        title: 'Ajouter bilan',
        key: 'ajouter_bilan',
        url: '/ajouter_bilan',
        icon: "fe fe-eye",
        roles:['admin','contact'],
      },
      {
        title: "Catégories de bilan",
        key: "category_bilan",
        url: "/category_bilan",
        icon: "fe fe-info",
        roles:['admin','contact'],
      }
    ],     
  },
  {
    category: true,
    title: "Communité de l'ITES",
    key: 'ItesCommunity',
    roles:['admin','inscrit'],
  },
  /*
  {
    title: 'Adhèrents',
    key: 'adhèrents',
    url: '/adherants',
    icon: 'fe fe-globe',
  },
*/
  {
    title: 'Messages',
    key: 'messages',
    url: '/messages',
    icon: 'fe fe-globe',
    roles:['admin','inscrit'],
  },
  {
    title: 'Inscrits',
    key: 'newsletter',
    url: '/newsletter',
    icon: 'fe fe-user',
    roles:['admin','inscrit'],
  },  
  {
    title: 'bibliographie',
    key: 'bibliographies',
    url: '/bibliographies',
    icon: 'fe fe-align-center',
    roles:['admin','bibliographies','add_bibliographies'],
    children: [
      {
        title: "Tous les Bibliothèques",
        key: "listerbib",
        url: "/bibliographies",
        icon: "fe fe-info",
        roles:['admin','bibliographies'],
      },
      {
        title: 'Ajouter bibliographie',
        key: 'ajouterBib',
        url: '/ajouterbib',
        icon: "fe fe-eye",
        roles:['admin','add_bibliographies'],
      },
      {
        title: "Tous les catégories",
        key: "category_bib",
        url: "/category_bib",
        icon: "fe fe-info",
        roles:['admin','bibliographies'],
      }      
    ],
  },
  // VB:REPLACE-END:MENU-CONFIG
]

import localeAntd from 'ant-design-vue/lib/locale-provider/ar_EG'

const messages = {
  topBar: {
    profileMenu: {
      hello: 'مرحبا',
      billingPlan: 'Billing Plan',
      role: 'الدور',
      email: 'البريد الإلكتروني',
      phone: 'الهاتف',
      editProfile: 'تعديل الحساب',
      logout: 'تسجيل الخروج',
    },
  },
  menu:{
    category:{
      contentCategory:"إدارة المحتوى",
      general:"عام",
      generalInformation:"المعلومات العامة",
      ItesCommunity:"مجتمع ITES",
    },
    items:{
      publications:"المنشورات",
      __dashboard: "الصفحة الرئيسية",
      accounts:"الحسابات",
      debate: 'مناظرة',
      poll:"تصويت",
      news:"الأخبار",
      agenda:"جدول الأعمال",
      contact: "الاتصال",
      adhèrents:"أعضاء",
      inscrits:"اشتركات في النشرة الإخبارية",
      listerPub:"جميع المنشورات",
      addPub:"إضافة منشور",
      lister_actualites:"كل الأخبار",
      ajouter_actualites:"إضافة أخبار",
      category_actualites:"كل الفئات",
      debats:"كل المناقشات",
      ajouterdebat:"أضف مناظرات",
      category_pub:"كل الفئات",
      messages:'الرسائل',
      newsletter:'المشتركين في النشرة الإخبارية',
      slider:"صور الواجهة",
      galerie:"صور العرض",
      lister_bilan:"جميع المراجعات",
      ajouter_bilan:"إضافة الميزانية العمومية",
      category_bilan:"فئات الميزانية العمومية" ,
      bibliographies:'المكتبات',
      listerbib:'قائمة المكتبات',
      ajouterBib:'أضف مكتبة',
      podcast:'تدوين صوتي', 
      video:'فيديوات',
      organigrame:"الهيكل التنظيمي",
      accessInformation:"الوصول إلى المعلومات",
      category_bib:"كل الفئات",       

    },
  },
}

export default {
  locale: 'ar-EG',
  localeAntd,
  messages,
}

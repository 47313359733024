<template>
  <div :class="$style.topbar">
    <div class="mr-4">
      <vb-fav-pages />
    </div>
    <div class="mr-8 d-none d-md-block">
    </div>
    <div class="mr-auto d-xl-block d-none">
    </div>
    <div class="mr-3 d-none d-sm-block ml-auto">
    </div>
    <div class="mr-3 d-none d-sm-block">
      <vb-language-switcher />
    </div>
    <div>
      <vb-user-menu @showModal="showModal" />
    </div>
    <a-modal
      v-model:visible="avatarModal"
      title="Changer le photo de profile"
    >
      <a-upload-dragger
      :multiple="false"
      :file-list='avatarFileList'
      :before-upload="beforeUpload"
      :custom-request="updateAvatar"
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Cliquez ou faites glisser le fichier dans cette zone pour télécharger</p>
        <p class="ant-upload-hint">
          Prise en charge d'un téléchargement unique ou groupé. Interdire strictement de télécharger des données d'entreprise ou
           autres fichiers de bande
        </p>
      </a-upload-dragger>
    </a-modal>
    <a-modal 
      v-model:visible="visible"
      title="Profile de l'utilisateur"
      :ok-text="editing?'Mettre à jour':'Modifier'"
      @ok="handleOk"
      @cancel="handleCancel"
    >
        <div class="d-flex flex-wrap align-items-center mb-2">
          <a-popover title="Cliquez pour mettre à jour l'avatar" trigger="hover" class="cflex-shrink-0 mr-4 mb-2 vb__utils__avatar hover-cursor" @click="changeAvatar"> 
            <a-avatar v-if="modelData.avatar" shape="square" :src="modelData.avatar" size="large" :class="$style.avatar"/>
            <a-avatar v-else shape="square"  size="large" :class="$style.avatar">
              <template #icon><UserOutlined /></template>
            </a-avatar>                       
          </a-popover>
          <div class="mb-2">
            <div class="text-dark font-size-18 font-weight-bold text-nowrap">
              {{modelData.fName}} {{modelData.lName}}
              <i class="align-text-bottom fe fe-check-square text-success ml-2 font-size-24" />
            </div>
          </div>
        </div>
        <a-form v-if="editing" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" label-align="left">
          <a-form-item label="Prénom">
            <a-input v-model:value="formState.fName" placeholder="Votre prénom..." />
          </a-form-item>
          <a-form-item label="Nom">
            <a-input v-model:value="formState.lName" placeholder="Votre nom..." />
          </a-form-item>
          <a-form-item label="E-mail">
            <a-input v-model:value="formState.email" placeholder="Votre E-mail..." />
          </a-form-item>
          <a-form-item label="Téléphone">
            <a-input v-model:value="formState.phone" placeholder="Votre numéro de téléphone" />
          </a-form-item>                    
        </a-form>
        <div v-else class="table-responsive">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td class="text-gray-6 pl-0">
                  E-mail
                </td>
                <td class="pr-0 text-right text-dark">
                  {{modelData.email}}
                </td>
              </tr>
              <tr>
                <td class="text-gray-6 pl-0">
                  Téléphone
                </td>
                <td class="pr-0 text-right text-dark">
                  {{modelData.phone || "________"}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </a-modal>      
  </div>
</template>

<script>
//import VbIssuesHistory from './IssuesHistory'
//import VbProjectManagement from './ProjectManagement'
//import VbSearch from './Search'
//import VbActions from './Actions'
//import VbCart from './Cart'
import VbLanguageSwitcher from './LanguageSwitcher'
import VbUserMenu from './UserMenu'
import VbFavPages from './FavPages'
import { computed, ref, reactive, toRaw } from 'vue'
import { UserOutlined, InboxOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import {  message } from 'ant-design-vue';
import ApiClient from "@/services/axios"
//import UserStore from "@/store/user"

export default {
  components: {
    VbLanguageSwitcher,
    VbUserMenu,
    VbFavPages,
    UserOutlined,
    InboxOutlined
  },
  setup(){
    const store = useStore()
    const user = computed(() => store.getters['user/user'])
    

    let modelData = reactive(user)    
    let visible = ref(false)
    let avatarModal = ref(false)
    const formState = reactive({
      fName:modelData.value.fName ,
      lName:modelData.value.lName ,
      phone:modelData.value.phone ,
      email:modelData.value.email,
    })
    let editing = ref(false)
    let avatarFileList = ref([])
    let avatar = ref()


    const handleOk = function(){
      if (!editing.value) editing.value = true
      else {
        const updateData = toRaw(formState)
        let data = {}
        for (const key of Object.keys(updateData)){
          if (updateData[key]) 
            data[key] = updateData[key]
        }          
        ApiClient.patch("/users",{
          query:{email:modelData.value.email},
          data
        })
        .then(function(){
          store.commit('user/SET_STATE',data)
          message.success("vos informations ont été mises à jour")
          editing.value = false 
          modelData.value = {...data,...toRaw(modelData)}
        })
        .catch((e)=>{
          message.error("L'opération a échoué")
          console.error(e.stack)
          editing.value = false
        })
      }
    }

    const showModal = () => {
      visible.value = true
    }
    const changeAvatar = () => {
      avatarModal.value = true
    }

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error(
          "Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!"
        );
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        message.error("L'image doit être inférieure à 5 Mo!");
      }
      if (isJpgOrPng && isLt2M) {
        avatar.value = file;
        avatarFileList.value = [file]
      }
      return isJpgOrPng && isLt2M;
    }
    const updateAvatar = ()=>{
      return new Promise((resolve, reject)=>{
        let formData = new FormData();
        const currentDate = new Date()
        formData.append(
          'avatar',
          avatar.value,
          user.value.fName+"_"+user.value.lName+"_"+currentDate.getTime()
        )
        formData.append("email",user.value.email)
        ApiClient
          .patch("/user/avatar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res)=>{
            const data = res.data.avatar
            store.commit('user/SET_STATE',{avatar:process.env.VUE_APP_API_URL+data})
            modelData.value = {...toRaw(modelData),avatar:process.env.VUE_APP_API_URL+data}
            message.success("Avatar updated")
            avatarModal.value = false
          })   
          .catch(()=>{
            message.warning("Could not update the avatar")
          })
          .finally(()=>resolve())
      })
    }
    return {
      updateAvatar,
      changeAvatar,
      avatarFileList,
      beforeUpload,
      avatarModal,
      showModal,
      visible,
      modelData,
      editing,
      handleOk,
      formState,
      handleCancel:()=>{
        editing.value = false
      },
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },      
      
    }
  }
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>

<style scoped>
.vb__utils__avatar:hover{
   cursor: pointer;
}
</style>
import localeAntd from 'ant-design-vue/lib/locale-provider/fr_FR'

const messages = {
  topBar: {
    issuesHistory: 'Problèmes',
    projectManagement: 'Projets',
    typeToSearch: 'Chercher...',
    findPages: 'Trouver des pages...',
    actions: 'Actes',
    status: 'Statut',
    profileMenu: {
      hello: 'Bonjour',
      billingPlan: 'Plan de facturation',
      role: 'Rôle',
      email: 'Email',
      phone: 'Téléphone',
      editProfile: 'Editer le profil',
      logout: 'Connectez - Out',
    },
  },
  menu:{
    category:{
      contentCategory:"Gérer contenue",
      general:"Géneral",
      generalInformation:"Information génerales",
      ItesCommunity:"Communuté de l'ITES",
    },
    items:{
      publications:"Publications",
      __dashboard: "Acceuil",
      accounts:"Comptes",
      debate: 'Débats',
      news:"Actualités",
      agenda:"Calendrier",
      contact: "Contact",
      adhèrents:"Adhèrents",
      inscrits:"Inscrits",
      listerPub:"Tout les publications",
      addPub:"Ajouter publication",
      lister_actualites:"Tous les Actualités",
      ajouter_actualites:"Ajouter Actualités",
      category_actualites:"Tous les catégories",
      debats:"Tous les Débats",
      poll:"Sondages",
      ajouterdebat:"Ajouter Débats",
      category_pub:"Tous les catégories",
      messages:'Les messages',
      newsletter:'Les inscrits au newsletter',
      video:'Vidéos de l\'ITES',
      accessInformation:"Accées a l\'information",
      organigrame:"Organigrame",
      slider:"Slider",
      galerie:"Galerie",
      lister_bilan:"Tous les bilans",
      ajouter_bilan:"Ajouter bilan",
      category_bilan:"Catégories de bilan",
      bibliographies:'Bibliothèques',
      listerbib:'List des Bibliothèques',
      ajouterBib:'Ajouter Bibliothèque',
      podcast:'Podcast',
      category_bib:"Tous les catégories",
    },
  },
}

export default {
  locale: 'fr-FR',
  localeAntd,
  messages,
}
